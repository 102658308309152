.form-group label {
    font-size: 12px;
    margin-bottom: 4px;
}
.form-control {
    font-size: 12px;
    padding: 10px;
}
.form-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    flex: 1;
}

.item .avatar {
    width: 100px;
    height: 100px;
    display: inline-grid;
    align-items: center;
    text-align: center;
}
.btn .edit-profile {
    position: absolute;
    bottom: 0;
    right: 12px;
}
.btn {
    cursor: pointer;
}

.nav a.active {
    background: rgba(0, 0, 0, 0.1);
}

.rs-modal-wrapper .fade.in {
    opacity: 0.8;
}
table tr td {
    text-align: left;
    padding-right: 6px;
}
.rs-picker-menu {
    z-index: 10000;
}
.rs-picker-select-menu-group {
    width: 100%;
}
.react-confirm-alert-button-group button:nth-child(2) {
    background: none;
    border: 1px solid #333;
    color: #333;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 9px;
    padding-bottom: 9px;
}
.rs-picker-toggle-value {
    font-size: 12px;
}
.rs-loader-backdrop {
    z-index: 10;
}
.rs-loader-spin,
.rs-loader-content {
    z-index: 100 !important;
}
.address-wrap {
    padding: 10px;
    border: 1px solid #dfdfdf;
}
.rs-modal-wrapper .fade.in {
    opacity: 0.8;
    background: rgb(211 211 211 / 90%);
}
.rs-modal-content {
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
}
.uploaded-image {
    object-fit: cover;
    width: 100%;
    height: 120px;
    display: flex;
}
.scroll-wrap {
    overflow: auto;
}
.user-profile-sidemenu {
    padding: 16px;
}
.user-details span {
    font-size: 12px;
}

.profile-avatar {
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    align-self: center;
    display: inline-grid;
    align-items: center;
    border: 3px solid #3d5064;
}
.products-outer {
    box-shadow: none !important;
}
.products-buttons .btn {
    padding: 5px 10px;
}
.products-buttons .btn.active {
    color: rgba(255, 255, 255, 0.87) !important;
    background-color: #a88add;
}
.product-actions button {
    height: 30px;
}
.products-nav {
    padding-left: 32px !important;
}
.products-nav button {
    background: none;
}
.products-nav button.active {
    border-bottom: 3px solid rgb(11 194 170);
}
.product-table {
    padding: 20px 16px;
    margin: 10px 0;
    background: #fff;
}
.product-table h2 {
    font-size: 16px;
    padding-left: 10px;
    margin-bottom: 22px;
}
.product-table-wrap {
    background: #f0f0f0;
}
.product-table .product-td {
    width: 25%;
}
.total-product-tr {
    background: rgba(120, 130, 140, 0.045);
}
.total-product-tr.group-total td {
    background: rgba(120, 130, 140, 0.06);
}
.period-outer {
    min-height: 1000px;
}
.period-outer button {
    font-size: 12px;
    padding: 10px 20px;
}

.disabled {
    pointer-events: none !important;
}
.nav > li > a .nav-text {
    font-size: 13.5px;
}
.timetracking-action-buttons button {
    width: 33%;
}
.period-outer .period-action-buttons button {
    margin-right: 10px;
}
.formatted-notification {
    padding: 1rem;
    white-space: break-spaces;
}

@media (max-width: 600px) {
    .table {
        font-size: 12px;
    }
    .table-nonfluid {
        width: auto !important;
    }
    .header-title {
        padding: 14px 5px;
    }
    .period-outer .rs-picker-daterange {
        width: 100% !important;
    }
    .period-outer .period-action-buttons {
        padding: 20px 5px;
        display: flex;
        justify-content: space-between;
    }
    .period-outer .period-action-buttons button {
        width: 48%;
        margin-right: 0;
    }
}
